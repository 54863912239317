@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import "~bootstrap/scss/bootstrap";
body {
  font-family: "Poppins" !important;
  background: #1d1d1d;
  overflow-x: hidden;
  position: relative;
  color: #fff;
}
.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
  z-index: 9999;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.7);
  .text-info {
    color: yellowgreen !important;
  }
}
.all-games-loader .text-info {
  color: yellowgreen !important;
}
.login-success {
  background: #32d17217;
  border: 1px solid #32d172;
  border-radius: 12px;
}
html {
  overflow-x: hidden;
}
.text--small {
  font-size: 10px;
}
.text--medium {
  font-size: 12px;
}
.text--normal {
  font-size: 14px;
}
.text---large {
  font-size: 16px;
}
.text--xl {
  font-size: 20px;
}
.text--2xl {
  font-size: 40px;
  font-weight: 600;
}
.blue--text {
  color: #2175e7;
  cursor: pointer;
}
.text--black {
  color: #000;
}
.custom-header {
  background: #000000;
  .menu-items {
    color: #fff;
    height: 73px;

    .menu-item {
      width: 120px;
      height: 100%;
      display: grid;
      place-items: center;
      cursor: pointer;
      font-weight: 200;
      font-size: 17px;
      i {
        font-size: 10px !important;
      }
    }
    .menu-item.active-menu-item,
    .menu-item:hover {
      background: transparent
        linear-gradient(180deg, #faeb8400 0%, #faea835d 100%) 0% 0% no-repeat
        padding-box;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 8px;
      font-weight: 400;
      font-size: 14px;
      border-image-source: linear-gradient(
        268deg,
        #f1b515 0%,
        #fffb9a 53%,
        #e1900b 100%
      );
      border-left: 0;
      border-right: 0;
      border-top: 0;
      span {
        margin-top: 6px;
      }
    }
  }
  .login-signup-btns {
    img {
      cursor: pointer;
      object-fit: contain;
    }
  }
}
.headlines {
  padding: 0;
  .headline1,
  .headline2 {
    border-top: 0.5px solid #484848b8;
    border-bottom: 0.5px solid #484848b8;
    font-size: 10px;
    font-weight: 200;
    height: 35px;
  }
  .headline1 {
    color: #ffd160;
    background: radial-gradient(circle at center 20px, #3b3b3b, #0f0f0f 100%)
      no-repeat;
    font-size: 12px;
    border-right: 0.5px solid #484848b8;
    white-space: nowrap;
    overflow: hidden;
    width: 20%;
    span img {
      width: 30px;
      height: 30px;
      margin-left: 6px;
      margin-top: -15px;
    }
    marquee {
      margin-top: 4px;
    }
  }
  .headline2 {
    background: radial-gradient(circle at 125px, #3b3b3b, #0f0f0f 40%) no-repeat;
    color: #fff;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 8px !important;
  }
}
.footer {
  background: #1d1d1d;
  border-top: 1px solid #484848b8;
  color: white;
  ul li {
    list-style: none;
    font-weight: 100;
    padding-top: 0.5rem;
  }
  .social-icons img {
    margin-right: 30px;
  }
  .card-strip {
    background: rgb(44, 36, 52);
  }
  .copyright {
    font-weight: 300;
    font-style: italic;
    color: rgb(123, 123, 123);
  }
}
.main-slider .slider-custom-style .slick-arrow {
  display: none !important;
}
.main-slider .slick-slide > div {
  margin: 0px 15px 2rem 15px;
}
.games-carousel .slick-slide > div {
  margin-right: 6px;
  margin-left: 6px;
}
.main-slider .slider-custom-style .slide-custom {
  //   width: 70% !important;
  // margin-right: 30px;
  img {
    width: 100%;
    object-fit: contain;
    box-shadow: 10px 25px 24px rgba(0, 0, 0, 0.3);
    border-radius: 40px;
  }
}
.slider-custom-style .slide-custom {
  //   width: 70% !important;
  // margin-right: 30px;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.slick-dots li button:before {
  font-size: 14px !important;
  color: #ffffff !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #ff231f !important;
}
.slick-dots li {
  width: 8px;
  height: 8px;
}
.slick-dots {
  bottom: -15px !important;
}
.games-carousel .slick-dots {
  display: none !important;
}
.custom-padding {
  padding: 2rem 7rem 2rem 7rem;
}
.search-bar {
  padding-top: 10px !important;
}
.menu-items strong {
  color: #fff;
}

.search-bar-item {
  border-bottom: 1px solid rgb(137, 137, 137);
  padding: 0px 15px 10px 15px;
  input {
    border: none;
    width: 100%;
    background: none;
    height: 50px;
    color: rgb(137, 137, 137);
    font-size: 24px;
  }
  input:focus {
    outline: none;
  }
}
.games-section {
  .game-card {
    position: relative;
  }
  h4 {
    position: absolute;
    bottom: 0.5rem;
    left: 1.5rem;
    color: #fff;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  img.custom-style {
    margin-top: -8px;
  }
}
.small-screen-footer {
  display: none;
}
.large-screen-footer {
  display: block;
  a {
    text-decoration: none;
    color: white;
  }
}

.bell-icon {
  position: relative;
  margin-top: 12px;
  i {
    position: absolute;
    transform: rotate(35deg);
  }
  span.notification-number {
    position: absolute;
    top: 2px;
    right: -12px;
    background: #e00a09;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 10px;
    user-select: none;
  }
}
.display--grid {
  display: grid;
  place-items: center;
}
.dropdown-toggle {
  &:after {
    display: none;
  }
}
.logged-in-user-data-in-header {
  .dropdown-menu {
    color: #fff;
  }
  i {
    font-size: 20px;
  }
  .header-username {
    color: #f4c22e;
    font-size: 12px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .balance-n-deposit {
    width: 210px;
    overflow: hidden;
    height: 55px;
    border-radius: 12px;
    border: 2px solid #f5ca3d;
    .balance {
      position: relative;
      cursor: pointer;
      width: 50%;
      color: black;
      padding: 6px 15px 10px 10px;
      background-image: linear-gradient(
        180deg,
        #f1b515 0%,
        #fffb9a 53%,
        #e1900b 100%
      );
      font-size: 12px;
      div {
        width: 100%;
        span.balance-value {
          font-size: 14px !important;
        }
      }
      .balance-refresh-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        place-items: center;
        i {
          color: #fff;
          font-size: 30px;
        }
        display: none;
      }
      &:hover {
        .balance-refresh-icon {
          display: grid;
        }
      }
      .show {
        display: grid !important;
      }
      .rotate-icon {
        animation: rotateIconAnimation 0.5s linear;
      }
      @keyframes rotateIconAnimation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
    .deposit {
      width: 50%;
      cursor: pointer;
      color: #efc04b;
      font-size: 8px;
      padding: 10px 6px;
    }
  }
  .dropdown-menu[data-bs-popper] {
    left: -300px;
    margin-top: 8px;
  }

  .dropdown-menu {
    background: #151515;
    width: 350px;
    padding: 1rem;
    strong {
      color: #f5ca3d;
      font-size: 12px;
    }
    li {
      background: #000;
      padding: 0.6rem;
      margin-top: 10px;
      border-radius: 6px;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
      p {
        font-size: 10px;
        margin-bottom: 0;
        color: rgb(179, 179, 179);
        font-weight: 300;
      }
      b {
        font-size: 12px;
      }
      div {
        margin-left: 10px;
        b.deposit {
          color: #37c084;
        }
        b.withdraw {
          color: #1cc2ff;
        }
        b.verified {
          color: #fff;
        }
      }
    }
  }
  .dropdown-toggle.show {
    color: #f7d149;
  }
  i.bi-list {
    font-size: 30px;
    margin-left: 15px;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}

.games-carousel .slide-custom {
  position: relative;
  div.overlay {
    position: absolute;
    background-image: none;
    width: 100%;
    height: 100%;
    color: #f5ca3d;
    font-size: 22px;
    div.game--name {
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      color: #000;
      padding: 0.3rem 1rem;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      border-radius: 5px;
      transition: 0.3s;
      p {
        margin-bottom: 0;
      }
    }
    i {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      font-size: 14px;
    }
    &:hover {
      div.game--name {
        opacity: 0;
      }
    }
  }
}
.games-carousel .slick-arrow {
  margin-top: -20px;
}
.slick-prev:before {
  content: "\2039" !important;
  font-size: 40px !important;
}
.slick-next:before {
  content: "\203A" !important;
  font-size: 40px !important;
}
.modal-open {
  padding-right: 0 !important;
}
.modal {
  padding-right: 0 !important;
  .modal-content {
    background: #1d1d1d;
    width: 80%;
    margin: auto;
    border-radius: 20px;
  }

  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
    padding: 1rem 2rem;
    i {
      cursor: pointer;
    }
    i.bi.bi-x-lg {
      width: 40px;
      height: 30px;
      display: grid;
      transition: 0.3s;
      place-items: center;
      &:hover {
        background: rgb(49, 49, 49);
        border-radius: 4px;
      }
    }
  }
  h2.modal-title {
    font-size: 16px !important;
  }
  .modal-body {
    padding: 2rem;
  }
}
.signup-modal {
  .modal-content {
    width: 90% !important;
  }
}
.password {
  width: 100%;
  background: #000;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  border-radius: 5px;
  position: relative;
  input {
    width: 100%;
    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
  i {
    display: grid;
    place-items: center;
    opacity: 0.6;
    position: absolute;
    right: 10px;
    top: 14px;
  }
}
.modal-dialog {
  b {
    font-size: 14px !important;
    font-weight: 400;
  }
  input {
    background: black;
    color: #fff;
    border: none;
    border-radius: 0px 5px 5px 0px;
    padding: 10px;
  }
  input.form-control:focus {
    box-shadow: none !important;
    color: #fff !important;
    outline: none;
    background: none !important;
    border: 1px solid #32d172;
  }

  .PhoneInput {
    background: black;
    border-radius: 5px;
    width: 100%;
  }
  .form-control.is-valid {
    border-color: transparent;
    padding: 0px 36px 0 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: 18px !important;
    display: flex;
    border: none !important;
  }
  p.remember-me {
    font-size: 10px;
    opacity: 0.5;
    font-weight: 200;
    margin-left: 5px;
  }
  .forgot-password {
    font-size: 10px;
    color: #31b3ff;
    cursor: pointer;
    user-select: none;
  }
  button {
    width: 100%;
    border-radius: 5px;
    color: black;
    font-size: 14px;
    border: none;
  }
  .login-btn {
    background-image: linear-gradient(
      105deg,
      #f1b515 0%,
      #fffb9a 53%,
      #e1900b 100%
    );
  }
  .signup-btn {
    background: none;
    border: 1px solid #f2cb5a;
    color: #fff;
  }
  p.not--account {
    font-size: 12px;
  }
}
body.modal-open .supreme--container {
  filter: blur(60px) !important;
}
.modal-backdrop {
  background: rgba(120, 120, 120, 0.243);
}
.incorrect-details-div {
  border-radius: 8px;
  border: 1px solid #e42d2d;
  p.message {
    border-bottom: 1px solid #e42d2d;
    margin-bottom: 0px;
    font-size: 10px;
    padding: 0.6rem 1rem;
  }
}
.chat-bot-option {
  padding: 0.6rem 1rem;
  cursor: pointer;
  img {
    margin-right: 10px;
    width: 36px;
  }
  div {
    margin-top: -4px;
  }
  div > b {
    font-size: 12px !important;
    color: #f2cb5a;
  }
  div > p {
    font-size: 8px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0px;
  }
}

.info--message {
  color: #31cb7a;
  font-size: 10px;
  font-weight: 300;
}
.normal--message {
  color: #fff;
  opacity: 0.4;
  font-size: 11px;
  font-weight: 300;
}
.error--text {
  font-size: 11px;
  font-weight: 300;
  color: red !important;
}
.referral-code {
  width: 100%;
  border-radius: 5px;
  background: #000;
  input {
    border-radius: 5px;
  }
  i {
    display: grid;
    place-items: center;
    margin-right: 5px;
    color: #32d172;
  }
  span {
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 12px;
    padding-right: 10px;
    color: #31b3ff !important;
    cursor: pointer;
    user-select: none;
  }
  span.verified {
    color: #32d172 !important;
  }
}
.signup-form p.remember-me {
  opacity: 1;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #393939;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #393939;
}

::-webkit-scrollbar-track {
  background-color: #000;
}
// .custom-recaptcha iframe {
//   width: 375px;
// }
// .custom-recaptcha .rc-anchor-normal {
//   width: initial !important;
// }
// #rc-anchor-container {
//   width: 375px !important;
// }
.right-menu-items {
  i {
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.otp-inputs {
  width: 100%;
  input {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
}
.modal.otp--modal .modal-content {
  width: 100%;
}
.offcanvas {
  background-color: #131313;
  .offcanvas-body {
    padding: 0;
    .offcanvas-body--upper {
      border-bottom: 0.6px solid #f5ca3d72;
    }
  }
}

.balance-info {
  background-image: linear-gradient(
    108deg,
    #f1b515 0%,
    #fffb9a 53%,
    #e1900b 100%
  );
  border-radius: 10px;
  border: 1px solid #fff3be;
  padding: 1rem 1rem 1.5rem 1rem;
}
.bal-progress {
  width: 100%;
  height: 5px;
  background: linear-gradient(269deg, #fa7d00 0%, #ffa000 100%);
  position: relative;
  .circle--progress {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fa7d00;
    border-radius: 50%;
    top: -2.8px;
    left: 72.4%;
  }
  .progress-value {
    position: absolute;
    left: 72.4%;
    top: 6px;
  }
}
.sidebar-menu-item {
  cursor: pointer;
  &:hover {
    background: #1b1b1b;
  }
  span {
    margin-top: 3px;
  }
}
.logout-btn {
  border: 1px solid #f5ca3d;
  border-radius: 8px;
  background-color: #131313;
  color: #fff;
  cursor: pointer;
}
.low-opacity {
  opacity: 0.7;
}
.legal-page-back {
  background-image: url("../images/legal_bg.webp");
  background-size: cover;
  .search-bar-item {
    border-bottom: 1px solid #fff;
    width: 70%;
    margin: auto;
    justify-content: center;
    input {
      color: #fff;
      font-size: 20px;
    }
  }
}
.terms-page-back {
  background-image: url("../images/terms_bg.webp");
  background-size: cover;
  .search-bar-item {
    border-bottom: 1px solid #fff;
    width: 70%;
    margin: auto;
    justify-content: center;
    input {
      color: #fff;
      font-size: 20px;
    }
  }
}
.legal-content p {
  // opacity: 0.7;
  font-weight: 200;
}

.container-with-sidebar {
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container-with-sidebar {
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar {
  padding: 0;
  background: #000;
  border-right: 0.5px solid rgba(255, 255, 255, 0.2);
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar li {
  border-top: 0.5px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  padding: 1rem;
  user-select: none;
}

.sidebar li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .active {
  color: #ffee75;
}

.sidebar .sub-menu {
  display: none;
  margin-left: 20px;
  margin-top: 5px;
}

.sidebar .sub-menu li {
  border-bottom: none;
  border-right: none;
  padding: 5px 10px;
}

.sidebar .sub-menu li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar .sub-menu .active {
  background-color: #ffee75;
}

.content {
  color: #fff;
  padding: 20px;
}

@media (max-width: 767px) {
  .sidebar {
    display: none;
  }
  .content {
    width: 100%;
  }
}
.sidebar .sub-menu-toggle .icon {
  margin-left: auto;
}

.sidebar .sub-menu {
  display: none;
  margin-left: 20px;
  margin-top: 5px;
}
i.submenu-arrow {
  float: right;
}
.container-with-sidebar {
  .card.card-body {
    background: #1c1c1c;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  }
}
li#profile {
  border-top: 0px;
}
.verification-menu {
  ul > li {
    border-top: 0px !important;
  }
}
.verification-active,
.verification-menu {
  background: #1c1c1c;
}
.text--light {
  font-weight: 200;
}
.phoneEmail-verification-input {
  border: 1px solid #707070;
  padding: 0.2rem;
  border-radius: 5px;
  display: flex;
  input {
    background: none;
    color: #fff;
    border: none;
    font-weight: 200;
    font-size: 14px;
    padding: 5px 10px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  button {
    background: linear-gradient(118deg, #f1b515 0%, #fffb9a 53%, #e1900b 100%);
    font-size: 12px;
    color: #000;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
  }
  .verified-badge {
    width: 20%;
    text-align: right;
    padding-right: 10px;
    display: flex;
    margin: auto;
    justify-content: end;
    i {
      font-size: 15px;
    }
    p {
      margin-top: 3px;
    }
  }
}
button.standard-btn {
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  background: linear-gradient(118deg, #f1b515 0%, #fffb9a 53%, #e1900b 100%);
  color: black !important;
  font-weight: 500;
}
.add-document-btn {
  width: 350px;
  font-size: 14px;
}
.document-upload-section {
  select {
    width: 100%;
    padding: 0.4rem 1rem;
    border: 1px solid #707070;
    background: none;
    color: #fff;
    border-radius: 5px;
    &:focus {
      outline: none;
      background: black;
    }
    option {
      padding: 10px;
      font-size: 16px;
      color: #fff;
      background-color: #000;
    }
  }
  div.file-input-div {
    width: 100%;
    height: 42px;
    border-radius: 5px;
    display: flex;
    padding-left: 10px;
    padding-top: 8px;
    text-align: left;
    border: 2px dashed rgb(95, 95, 95);
    background-color: #000;
    position: relative;
    cursor: pointer;
    .file {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
    }
  }
  .checkbox {
    p {
      font-weight: 200;
      margin-top: -5px;
      opacity: 0.9;
    }
  }
}
.standard-btn-div {
  text-align: right;
  button {
    width: 300px;
  }
  margin-top: 150px;
}
.document-status {
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid #707070;
  .document-status-title-img {
    img {
      margin-top: -10px;
    }
  }
}
.open-camera {
  border-color: #f5ca3d;
  border-style: dashed;
  padding: 0.8rem;
  border-spacing: 5px !important;
  border-inline-width: 5px;
  border-width: 1px;
  border-radius: 5px;
}
.banks-to-be-added {
  i {
    color: #414141;
    font-size: 20px;
    margin-top: -5px;
  }
  img {
    margin-top: -12px;
  }
}
.added-banks-list .form-check {
  position: relative;
  .primary-account {
    position: absolute;
    right: 10px;
    top: 5px;
    i {
      color: #29bd5a;
    }
  }
}
.new-account {
  .modal-header {
    border-bottom: 1px solid rgb(92, 92, 92);
  }
  .modal-content {
    width: 100% !important;
    border-radius: 0px;
    background: #000;
    input {
      width: 100%;
      padding: 1rem;
      border: 1px solid #707070;
      border-radius: 5px;
      position: relative;
    }
    .if--info {
      position: relative;
      i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 25px;
        color: #32d172;
      }
    }
  }
}
.menu-items .dropdown-menu {
  background: #151515;
}
.sub-menu-dropdown {
  color: #fff;
  width: 210px;
  p {
    padding-top: 0.5rem;
    padding-left: 1.6rem;
    margin-bottom: 5px;
  }
  hr {
    margin-top: 5px;
    margin-bottom: 0;
  }
  ul {
    padding-left: 0;
    li {
      padding: 1rem 1.6rem;
      list-style: none;
      font-size: 12px;
      font-weight: 200;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    li.active {
      background: black;
    }
  }
}
.table {
  color: #fff;
  opacity: 0.72;
  font-weight: 200;
  font-size: 14px;
  th {
    padding: 1rem;
    font-weight: 400;
  }
  tbody {
    border-left: 1px solid #7070704f;
    border-right: 1px solid #7070704f;
    td {
      padding: 1rem;
    }
  }
}
.table > :not(caption) > * > * {
  border-bottom: 1px solid #7070704f;
}
.update-password-container {
  label {
    opacity: 0.7;
  }
  .password {
    position: relative;
    padding-right: 0;
    input {
      background: black;
      border: 1px solid #707070;
      border-radius: 5px;
      color: #fff;
      padding: 0.8rem;
      width: 100%;
      &:focus {
        border: 1px solid #707070 !important;
      }
    }
    i {
      position: absolute;
      right: 16px;
      top: 18px;
    }
  }
}
.border-btn {
  background: none;
  border: 1px solid #f8d95a;
  border-radius: 5px;
  color: #fff;
}
.update-password-container {
  .border-btn {
    width: 48%;
  }
  .standard-btn {
    width: 48%;
  }
}
.choose-date-input {
  border: 1px solid #707070;
  padding: 0.5rem 1rem;
  #date {
    border: none;
    outline: none;
    background: none;
    color: #fff;
    opacity: 0.7;
  }
  i {
    font-size: 16px;
    margin-right: 5px;
    color: #fff;
  }
}
.hamburger {
  margin-left: 1rem;
}
.calendar-table {
  background: #1d1d1d !important;
  border: none !important;
}
.daterangepicker {
  background: #1d1d1d !important;
  border: 1px solid #707070 !important;
}
.off.ends.available {
  background: #000 !important;
}
.in-range.available {
  background: rgb(90, 90, 90) !important;
  color: #fff !important;
}
.active.start-date.available,
.active.end-date.in-range.available {
  background: #f5c839 !important;
}
.daterangepicker .drp-buttons {
  border-top: 1px solid #707070 !important;
}
.applyBtn {
  background: #f5c839 !important;
  border: none !important;
}
.daterangepicker .calendar-table .prev span {
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}
.daterangepicker .calendar-table .next span {
  border: 1px solid #fff !important;
  border-width: 0 2px 2px 0 !important;
}
.daterangepicker::after {
  display: none !important;
  top: -6px;
  border-right: 6px solid #1d1d1d !important;
  border-bottom: 6px solid #1d1d1d !important;
  border-left: 6px solid #1d1d1d !important;
}
.custom-date-picker input {
  width: 100%;
  background: black;
  padding: 0.3rem 0.6rem;
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: 200;
  border-radius: 5px;
}
.date-range-input {
  border: 1px solid #707070;
  border-radius: 5px;
  position: relative;
  i {
    position: absolute;
    right: 14px;
    top: 12px;
  }
}
.filter-payment-method,
.filter-game {
  height: 30px;
  border: 1px solid #707070;
}
.form-select {
  background: #171717 !important;
  color: #fff !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' className='bi bi-caret-down-fill' viewBox='0 0 16 16'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") !important;
  width: 40%;
  border-radius: 0px;
  &:focus {
    box-shadow: none !important;
  }
  font-size: 12px;
}
.selected--method {
  width: 60%;
}
.filter-game .form-select {
  background: #000 !important;
}
.deposit--modal {
  .modal-content {
    border-radius: 0px;
    background: #000;
    width: 95% !important;
    .modal-body {
      padding: 0;
    }
    .modal-header {
      border-bottom: 0.5px solid #70707071 !important;
    }
  }
  .payment-options {
    // button.slick-arrow {
    //   width: 0 !important;
    // }
    // button.slick-arrow#text {
    //   display: none !important;
    // }
    // .slick-track {
    //   margin: 10px !important;
    // }
    // // .slick-prev:before {
    // //   content: "\F284" !important;
    // //   font-size: 20px !important;
    // // }
    // // .slick-next:before {
    // //   content: "\F285" !important;
    // //   font-size: 20px !important;
    // // }
    // .slick-next {
    //   right: -12px !important;
    // }
    .slick-list {
      overflow: visible !important;
    }
    .slick-slide {
      cursor: pointer;
    }
    .slick-active.slick-current {
      border-radius: 5px;
    }
    .selected-slide {
      border: 1px solid #efc04b;
      border-radius: 5px;
    }
  }
  .deposit-col1 {
    border-right: 0.5px solid #70707071;
  }
}
.amount-input > div {
  background: #0b0b0b;
  position: relative;
  border-radius: 5px;
  input {
    background: none;
    width: 100%;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
    }
    font-size: 20px;
    opacity: 1;
    font-weight: 300;
  }
  span {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    opacity: 1;
    font-weight: 400;
  }
}
.amount {
  color: #fff !important;
  opacity: 0.75 !important;
  border: none;
  cursor: pointer;
  padding: 0.6rem 1.1rem;
  border-radius: 5px;
  font-size: 12px;
  background: #353535;
  margin-right: 6px;
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
  user-select: none;
}
.amount:hover {
  background: linear-gradient(
    180deg,
    rgb(233, 184, 49) 0%,
    rgba(255, 251, 154, 1) 50%,
    rgb(233, 184, 49) 100%
  ) !important;
  color: #000 !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  transform: scale(0.95) !important;
}
.available-offers-container {
  .available-offers {
    height: 150px;
    overflow-y: scroll;
    padding-left: 1.5rem;
    padding-right: 1rem;
  }
  label {
    color: #32d172;
    padding-left: 1.5rem;
    font-size: 11px;
  }
}
.offer-card {
  padding: 0.5rem 1rem;
  .offer-detail {
    font-weight: 300;
    opacity: 0.75;
  }
  cursor: pointer;
  * {
    user-select: none;
  }
}
.active-offer {
  border: 1px solid #f5ca3dc3 !important;
  border-radius: 5px;
}
.deposit-col2 {
  position: relative;
}
.action-btns {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  width: 92%;
  .border-btn {
    width: 35%;
    font-size: 10px;
  }
  .standard-btn {
    width: 60%;
  }
}

.form-check-input {
  &:focus {
    box-shadow: none;
  }
}
.upi-id-div {
  background-color: #0b0b0b;
  padding: 0.5rem 1.4rem;
  border-radius: 5px;
  > div.upi_id {
    border: 1px dashed #a3a3a3b7;
    border-radius: 5px;
    position: relative;
    padding: 11px;
    background: #22222280;
  }
  .copy-btn {
    background: #23ca2e;
    font-size: 10px;
    color: #fff;
    width: 100px;
    position: absolute;
    top: 4px;
    right: 5px;
    padding: 0.5rem 1rem;
  }
}
// .bank-lists {
//   height: 300px;
//   overflow-y: scroll;
// }
.modal.payment-completion-modal .modal-content {
  background: #000;
  border-radius: 0px;
  width: 100%;
}
.user-profile {
  button.cancel {
    background: none;
    color: #fff;
    font-size: 12px;
    border: none;
    padding: 0.5rem 1.5rem;
  }
  button.save {
    background: #32d172;
    color: #fff;
    font-size: 12px;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
  }
  .user-info-form {
    input {
      color: #fff;
      font-size: 14px;
      padding: 0.4rem 1rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.custom-header a {
  text-decoration: none;
  color: #fff;
}
.game-card {
  position: relative;
  i {
    position: absolute;
    top: 10px;
    right: 30px;
  }
  div.game--name {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    color: #000;
    padding: 0.3rem 1rem;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    border-radius: 5px;
    transition: 0.3s;
    p {
      margin-bottom: 0;
    }
  }
  .see-more-parent {
    position: absolute;
    width: 95%;
    background: rgba(255, 255, 255, 0.19);
    backdrop-filter: blur(5px);
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .see-more {
    width: 150px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    background: black;
    padding: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 10px;
    p {
      margin-left: 15px;
      display: grid;
      place-items: center;
    }
  }
  &:hover {
    div.game--name {
      opacity: 0;
    }
  }
}
.filters {
  position: fixed;
  left: 0px;
  top: 600px;
  cursor: pointer;
  p {
    transform: rotate(270deg);
  }
}
.filter-menu {
  width: 103%;
  background: #000;
  z-index: 10;
  margin-top: 0px;
  min-height: 300px;
  max-height: 100vh;
  overflow-y: scroll;
  position: relative;
}
.sticky {
  position: fixed !important;
  width: inherit;
  top: 0px;
}
.categories_filter {
  p {
    font-size: 20px;
  }
  i {
    display: grid;
    place-items: center;
  }
}
.providers_filter {
  p {
    font-size: 20px;
  }
  i {
    display: grid;
    place-items: center;
  }
}
.filter-item {
  input {
    width: 22px;
    height: 22px;
    overflow: hidden !important;
    &:checked {
      background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iZXdHVWxvcFRqT3gxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMzAwIDMwMCIgc2hhcGUtcmVuZGVyaW5nPSJnZW9tZXRyaWNQcmVjaXNpb24iIHRleHQtcmVuZGVyaW5nPSJnZW9tZXRyaWNQcmVjaXNpb24iPjxyZWN0IHdpZHRoPSIzMDAiIGhlaWdodD0iMzQyLjg1NTk2NyIgcng9IjM0IiByeT0iMzQiIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIDAuODc1MDAzIDAgMS4wOTk3MTYpIiBmaWxsPSIjMzJkMTcyIiBzdHJva2Utd2lkdGg9IjAiLz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTEuMzE3NzI2LTk1LjEzNDkyNikiPjxyZWN0IHdpZHRoPSIxMTguMjYxODI2IiBoZWlnaHQ9IjE0LjMwMTQzIiByeD0iMyIgcnk9IjMiIHRyYW5zZm9ybT0ibWF0cml4KDEuMzMyMTU5LTEuMTc0NzAyIDIuNTY4NzEzIDIuOTEzMDIzIDE4My4zMTU4NjUgMjkzLjI2NTg4NSkiIGZpbGw9IiNmZmYiIHN0cm9rZS13aWR0aD0iMCIvPjxyZWN0IHdpZHRoPSIxMTguMjYxODI2IiBoZWlnaHQ9IjEzLjc1MTM3NSIgcng9IjIiIHJ5PSIyIiB0cmFuc2Zvcm09Im1hdHJpeCguODI2ODU1IDAuODcyMTk2LTIuNjcxNDYxIDIuNTMyNTg4IDE1NS43OTEyOTEgMTk5Ljk1MjI4MykiIGZpbGw9IiNmZmYiIHN0cm9rZS13aWR0aD0iMCIvPjwvZz48L3N2Zz4NCg==") !important;
    }
  }
  h6 {
    font-weight: 300;
  }
}
.see-all {
  i {
    font-size: 14px;
  }
  button {
    background: #343434;
    border: none;
    color: #fff;
  }
}
#sidebarMenu {
  width: 350px;
}
.hide-filter-btn {
  display: none !important;
}
.search-bar-item {
  position: relative;
}
.search--results {
  position: absolute;
  width: 100%;
  top: 120%;
  left: 0;
  background: rgba(59, 59, 59, 0.762);
  max-height: 320px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 20px;
  z-index: 100;
  backdrop-filter: blur(8px);
  ul {
    a {
      color: #fff;
      text-decoration: none;
      li {
        list-style: none;
        font-size: 20px;
        padding-bottom: 1rem;
      }
    }
  }
}
.mobile-filter {
  display: none;
}
.mobile-filters {
  display: none !important;
}
.form-mobile {
  display: none;
}
.mobile-content {
  display: none;
}
.footer-submenu-container {
  display: none;
}
input[type="date"] {
  padding-right: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.payment-slider {
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;
  background: #232323;
  overflow: hidden;
  display: flex;
  &:hover {
    .payment-slide {
      animation-play-state: paused;
    }
  }
}
.statement {
  background: #e42d2d1f;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 300;
  opacity: 0.8;
}
.parent-winner-slider {
  overflow: hidden;
  display: flex;
  &:hover {
    .winner-slider {
      animation-play-state: paused;
    }
  }
}
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.winner-slider {
  animation: 20s slide infinite linear;
}
.payment-slide {
  animation: 20s slide infinite linear;
}
.winner-slider > div {
  min-width: 300px !important;
  border-right: 1px solid #707070 !important;
}
.rbt-input-main.form-control.rbt-input {
  background: #000 !important;
  border: 1px solid rgba(255, 255, 255, 0.527);
}
#basic-typeahead-country,
#basic-typeahead-state,
#basic-typeahead-city {
  background: #1d1d1d !important;
  border: 1px solid #707070;
  .dropdown-item {
    color: #a2a2a2 !important;
    &:hover {
      background: #2a2a2a !important;
    }
  }
  .rbt-highlight-text {
    background-color: transparent !important;
    color: #a2a2a2 !important;
  }
  mark {
    padding: 0 !important;
  }
}
.games-carousel .slick-track {
  margin-left: initial !important;
}
.menu-items * {
  user-select: none;
}
.toast {
  z-index: 9999;
  border: 1px solid #23ca2e;
  .toast-body {
    color: #23ca2e;
  }
  button {
    color: #23ca2e !important;
  }
}
.games a {
  color: #fff;
}
.games-menu a {
  color: #fff;
  text-decoration: none;
}
.blue--text {
  color: #2175e7 !important;
}
.games {
  min-height: 700px;
}
#password {
  border-radius: 5px !important;
}
span.forgot-password {
  margin-top: 2.5px;
}
.login-signup-btns {
  .login-btn {
    background-image: linear-gradient(
      105deg,
      #f1b515 0%,
      #fffb9a 53%,
      #e1900b 100%
    );
    border: none;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    font-size: 14px;
  }

  .signup-btn {
    background: none;
    border: 1px solid #f2cb5a;
    color: #fff;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    font-size: 14px;
  }
}
.account {
  a {
    text-decoration: none;
  }
}
.header-parent-div {
  position: relative;
}
.header-parent-div .header {
  min-width: 24.6%;
}
.form-check-input[type="radio"] {
  border-radius: 5px !important;
}
.id-number {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 5px;
  background: #000;
  padding: 7px 10px;
  color: #fff;
}
.red-border {
  border: 1px solid red !important;
}
/* Hide the default number input arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the number input arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.sidebar-menu-item {
  a {
    text-decoration: none;
    color: inherit;
  }
}

.balance-n-deposit .deposit {
  background: linear-gradient(180deg, #535353 6%, rgb(20, 20, 20) 23%);
}
.PhoneInputCountry {
  background: #353535;
  padding-left: 10px;
  padding-right: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.login-form .text-input input {
  padding: 10px !important;
  font-size: 13px;
  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.signup-form .text-input input {
  padding: 10px !important;
  font-size: 13px;
  &:focus {
    outline: none !important;
    border: none !important;
  }
}
// .PhoneInputCountryIcon img {
//   width: 16px;
//   height: ;
// }
.PhoneInputCountryIcon--border {
  width: 16px;
  height: 10px;
}
.password i {
  top: 13px;
  font-size: 12px;
}
.PhoneInput--focus {
  outline: none !important;
  border: none !important;
}
.standard-btn.change-password {
  padding: 0.5rem 1rem;
  font-size: 12px;
  border-radius: 3px;
}
.table-container {
  width: 100%;
  overflow-x: scroll;
  border: 0.5px solid #70707054;
  &::-webkit-scrollbar-track {
    background-color: rgb(31, 31, 31) !important;
  }
}

.pagination-table {
  position: relative;
  margin-bottom: 0;
  td {
    text-align: center;
    font-size: 14px;
  }
  .cancel-btn {
    background-image: linear-gradient(
      180deg,
      #cfe71d 0%,
      #fffb9a 53%,
      #cfe71d 100%
    );
    padding: 0.2rem 0.8rem;
    border: none;
    border-radius: 4px;
    font-size: 10px;
  }
}
.pagination-table .td-item {
  min-width: 115px;
  padding: 0px !important;
  div {
    font-weight: 400;
    padding: 5px !important;
  }
}
.pagination-table th {
  min-width: 115px;
  padding: 0px !important;
  div {
    font-weight: 400;
    padding: 15px !important;
  }
}

.filter-payment-method {
  font-size: 12px;
  position: relative;
  .table-filter-popup {
    position: absolute;
    top: 140%;
    right: 0;
    background: #1d1d1d;
    z-index: 10;
    border-radius: 5px;
    padding: 16px 16px;
  }
}
.custom-date-picker {
  input[type="checkbox"] {
    width: initial !important;
  }
}
.table-filter-popup {
  width: 220px;
}
.filter-footer {
  button.cancel {
    border: 1px solid white;
    border-radius: 4px;
    background: none;
    color: #fff;
    font-size: 10px;
    width: 90px;
    padding: 0.5rem;
  }
  button.apply {
    background-image: linear-gradient(
      180deg,
      #f1b515 0%,
      #fffb9a 53%,
      #e1900b 100%
    );
    border: none;
    border-radius: 4px;
    width: 90px;
    padding: 0.5rem;
  }
}
span.chip {
  background: #1e1e1e;
  /* border: 1px solid #707070; */
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 8px;
  margin-bottom: 6px;
  margin-top: 2px;
  margin-right: 4px;
}
div.selected-chips {
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
.pagination {
  float: right;
  li {
    background: #a2a2a2;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    width: 20px;
    height: 20px;
    margin: 4px;
    display: grid;
    place-items: center;
    cursor: pointer;
    a {
      i {
        color: #fff;
      }
    }
  }
  li.selected {
    background-image: linear-gradient(
      180deg,
      #f1b515 0%,
      #fffb9a 53%,
      #e1900b 100%
    );
    color: #000;
  }
  li.disabled {
    background: #fff !important;
    a {
      i {
        color: #000 !important;
      }
    }
  }
  li.next,
  li.previous {
    background-image: linear-gradient(
      180deg,
      #f1b515 0%,
      #fffb9a 53%,
      #e1900b 100%
    );
    a {
      i {
        color: #000;
      }
    }
  }
}
.yes-btn {
  background-image: linear-gradient(
    180deg,
    #f1b515 0%,
    #fffb9a 53%,
    #e1900b 100%
  );
  color: #000;
  font-weight: 600;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  width: 80px !important;
}
.no-btn {
  background: none;
  color: #fff !important;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid #fff !important;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  width: 80px !important;
}
.cancel-withdraw-modal .modal-dialog {
  background: #1d1d1d;
}

.modal.cancel-request-modal {
  .modal-content {
    border-radius: 10px !important;
  }
}
.withdrawl-left {
  .balance-info b {
    font-size: 10px !important;
    font-weight: 500 !important;
  }
  .balance-info {
    border: none !important;
    border: 1px solid #fff3be !important;
  }
}

.mobile-login-signup {
  display: grid;
  place-items: center;
  margin-right: 8px;
  .btn-group {
    border: 1px solid #ffdf4c;
    border-radius: 10px;
    overflow: hidden;
    .login-left {
      background-image: linear-gradient(
        180deg,
        #f1b515 0%,
        #fffb9a 53%,
        #e1900b 100%
      );
      color: black;
      font-size: 14px;
      padding: 8px 16px;
    }
    .signup-right {
      background: none;
      color: #fff;
      font-size: 14px;
      padding: 8px 16px;
    }
  }
}
.login-history {
  height: 386px !important;
  overflow-y: auto;
}
.id-div {
  input:focus {
    background: #000 !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #707070 !important;
    color: #fff !important;
  }
}
.mobile-main-foooter {
  margin-bottom: 100px;
  color: #888888;
  a {
    text-decoration: none;
    color: #888888;
  }
  .footer-title {
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  ul {
    li {
      list-style: none;
      text-align: left;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 8px;
    }
  }
  .share {
    .footer-title {
      text-align: center !important;
    }
    .social-icons {
      display: flex;
      justify-content: center;
      img {
        width: 30px;
        margin: 5px;
      }
    }
  }
}
.offer-code-input {
  font-size: 15px !important;
}
.payment-detail {
  font-weight: 200 !important;
}
.amount-info {
  font-size: 18px;
  background: #0b0b0b;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 0.8rem;
  span {
    font-size: 20px;
    font-weight: 600 !important;
  }
}
.scan-qr-statement {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;
}
.upi-id-div {
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
.trn-utr {
  input {
    border: 1px solid #707070;
    padding: 0.7rem 1rem;
    width: 100%;
    font-size: 13px;
    border-radius: 5px !important;
    &:focus {
      border: 1px solid #707070 !important;
      outline: none !important;
    }
    &::placeholder{
      color: rgb(93, 93, 93) !important;
    }
  }
}
.total-bal {
  p.text--normal {
    font-size: 17px;
    font-weight: 400;
  }
  text-align: center;
  display: grid;
  place-items: center;
  p.mb-0 b {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}
.withdrawl-bal {
  p.text--normal {
    font-size: 17px;
    font-weight: 400;
  }
  text-align: center;
  display: grid;
  place-items: center;
  p.mb-0 b {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}
.bonus-bal {
  p.text--normal {
    font-size: 17px;
    font-weight: 400;
  }
  text-align: center;
  display: grid;
  place-items: center;
  p.mb-0 b {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}
input.bank-checkbox-input {
  appearance: none;
  outline: 3px solid #707070;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  padding: 4px !important;
  margin-top: 4px;
  margin-left: 2px;
  &:checked {
    background-color: #29bd5a;
  }
}
.added-banks-list .form-check {
  padding-left: 0px !important;
  .checkbox-div {
    margin-top: 10px;
  }
}
.checkbox-div .form-check-label {
  padding-left: 3px;
}
.payment-error {
  margin-right: 16px;
}
.bank-text {
  margin-left: 27px !important;
}
.banner-vdo {
  border-radius: 40px;
  box-shadow: 10px 25px 24px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
}
.copy-btn span {
  position: absolute;
  width: 100px;
  background: #444444;
  padding: 5px;
  border-radius: 3px;
  top: -25px;
  left: 0;
  div {
    position: absolute;
    top: 80%;
    left: 45%;
    transform: rotate(45deg);
    height: 10px;
    width: 10px;
    background: #444444;
  }
}
.copy-img span {
  left: -35px !important;
}
.copy-btn span.not-copied {
  opacity: 0;
  z-index: -1;
}
.copy-btn span.copied {
  opacity: 1;
  z-index: 1;
  top: -40px;
}
#id_upi {
  user-select: none;
}
#from_date,
#to_date {
  width: 100%;
  padding: 0.8rem 0.8rem;
  font-size: 14px;
  font-weight: 200;
  border: 1px solid #707070 !important;
  height: 41px !important;
}
.filter-payment-method {
  height: 48px !important;
  border: 1px solid #707070 !important;
}
// .table {
//   table-layout: fixed;
// }
.finance-table {
  .table td:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background: black !important;
    color: white !important;
    div {
      border-left: 0.5px solid #70707054;
      background-color: #000 !important;
      border-right: 0.5px solid #70707054;
      margin-right: -2px;
      // border-top: 0.5px solid #70707054;
    }
  }
  .table th:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background: black !important;
    color: #fff !important;
    div {
      border-left: 0.5px solid #70707054;
      background-color: #000 !important;
      border-right: 0.5px solid #70707054;
      margin-right: -1px;
    }
  }
}

.video-slide {
  position: relative;
  i {
    position: absolute;
    font-size: 35px;
    right: 2rem;
    bottom: 1rem;
    cursor: pointer;
    display: none;
  }
  &:hover {
    i {
      display: block;
    }
  }
}
.chat--box {
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  z-index: 10;
  border: 1px solid #efc04b;
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  width: 324px;
}
.chatbox--icon {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  // background: #1c1c1c;
  cursor: pointer;
}
.chatbox-header {
  background: #1e1e1e;
  border-bottom: 1px solid #efc04b;
  position: relative;
  i {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    font-size: 20px;
  }
}

.logout-btn-div {
  position: absolute;
  width: 100%;
  bottom: 1rem;
}
.normal-btn {
  border: 1px solid #fff !important;
  border-radius: 20px !important;
  background: none !important;
  color: #fff !important;
  font-size: 14px !important;
}
.btns {
  .use-here-btn {
    font-size: 12px;
    color: #000;
    padding: 8px 10px;
    font-weight: 600;
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgb(233, 184, 49) 0%,
      rgba(255, 251, 154, 1) 50%,
      rgb(233, 184, 49) 100%
    ) !important;
    margin-right: 5px;
    width: 100px;
  }
  .close-btn {
    margin-right: 10px;
    font-size: 12px;
    padding: 8px 10px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;
    margin-left: 5px;
    width: 100px;
  }
}
.alert-popup {
  width: 57% !important;
  i {
    font-size: 80px;
    opacity: 0.35;
  }
}
button.copy-info-btn {
  background: none;
  border: none;
  text-align: center;
  &:active {
    transform: scale(0.9);
  }
}
.mobile-spacing {
  margin-bottom: 150px;
}
.verify-otp {
  &:hover {
    text-decoration: underline;
  }
}
.white-stp {
  background: radial-gradient(
    circle,
    rgba(29, 29, 29, 1) 0%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%,
    rgba(29, 29, 29, 1) 100%
  );
  color: #000;
  padding: 3px 25px;
  font-size: 12px;
  font-weight: 500;
}
.products-col {
  padding-left: 80px;
  li {
    cursor: pointer;
  }
}
.legal-content {
  h1 {
    font-size: 20px;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 16px;
    opacity: 0.8;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 12px !important;
    color: #e0e0e0 !important;
    // opacity: 0.8 !important;
  }
}
.delete-bank-popup {
  position: relative;
  .bi-x-lg {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    // background: #000;
    transition: 0.3s;
    display: grid;
    place-items: center;
    &:hover {
      background: #000;
    }
  }
}
.margin--bottom {
  margin-bottom: 4rem;
}
.payment-status {
  width: 40%;
}
.payment-status-parent {
  padding-top: 150px;
  padding-bottom: 150px;
}
.slick-prev,
.slick-next {
  top: 11px !important;
}


.modal .modal-body.astrology-modal-body{
  margin: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  input{
    border-radius: 5px !important;
    opacity: .6;
    font-size: 14px !important;
  }
}
.astrology-modal .modal-content{
  background-image: url(../images/astroformback.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.horo-section{
  border: 2px solid rgba(139, 139, 139, 0.406);
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.765);
  height: 500px;
  overflow: hidden;
  .horo-child-section{
    max-height: 300px;
    overflow-y: scroll;
  }
  .timeframe-dropdown{
    width: 140px;
    position: relative;
    .selected-timeframe{
      background: black;
      opacity: 0.7;
      border: 1px solid #E9AB2F;
      border-radius: 30px;
      padding: 12px 25px;
      cursor: pointer;
      width: 140px;
    }
    .dropdown-items{
      position: absolute;
      top: 100%;
      left: 0;
      background: #313131;
      width: 90%;
      border-radius: 25px;
      padding: 0.7rem 0;
      li{
        padding: 10px 16px;
        cursor: pointer;
      }
      .checked-item{
        color: #E9AB2F;
      }
    }
  }
  
}
.numerology-num-p{
  width: 100%;
  height: 100%;
  .numerology-number{
    padding: 2rem 0;
    background: #111111;
    display: grid;
    place-items: center;
    text-align: center;
    border-radius: 20px;
    h1{
      font-size: 100px;
      font-weight: 800;
    }
    p{
      font-size: 25px;
      font-weight: 600;
      opacity: 0.6;
    }
  }
}
.astrology-modal .modal-dialog button.login-btn{
  font-size: 16px !important;
}
.align-center{
  display: grid;
  place-items: center;
}
.top-cards{
  padding: 1rem 2rem;
}

.astro-user-detail-card{
  // width: 30%;
  position: relative;
  // background-image: url(../../assets/images/astrorectback.png);
  background-size: contain;
  border: 4px solid #FFD700;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.991);
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 2.7rem 0 !important;
  margin-bottom: 2rem;
  background: #111111;
  background-repeat: no-repeat;
  img.card-back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  // > div{
  //   z-index: 2;
  // }
}
.hero-section-2{
  border: none;
}
.horo-list{
  list-style: none;
  padding-left: 0;
}
.exit-btn{
  background: #FCEB7C;
  color: #000;
  font-weight: 600;
  border: none;
}
.exit-btn:hover{
  background-color: #fceb7ccc;
  color: #000;
}
.horo-item:hover{
  opacity: 1;
}
.inactive-horo-item{
  opacity: 0.6;
}
.font-light{
  font-weight: 300;
}
.font-medium{
  font-weight: 500;
}
.astro-error-card{
  background: black;
  width: 100%;
  max-width: 450px;
  margin: auto;
  border-radius: 25px;
  padding: 6rem 2rem;
  text-align: center;
}
.astro-error-page{
  min-height: 100vh;
  background-image: url(../images//astromessageback.png);
  background-size:  cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 100px;
}
.astro-main{
  background-image: url(../images/astro-main-back.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}
img.card-back{
  width: 100%;
  
}
.astro-user-detail-card .content{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.astro-user-detail-card .content.content1{
  transform: translate(-90%, -50%);
}
.hero-section-2{
  background-image: url(../images/astroRight2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.hero-section-3{
  border: none;
  background-image: url(../images/astroRight.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: auto !important;
}
#externalIframe, #externalIframeAviatrix{
  min-height: 90vh !important;
}

.numerology-scroll-section{
  max-height: auto;
  // overflow-y: scroll;
  padding: 1rem;
}
.hero-section-2{
  height: auto !important;
}
.panchang-overlay{
  background: rgba(0, 0, 0, 0.582);
  padding: 3rem;
  height: 100%;
  .standard-btn{
    border-radius: 30px;
    height: fit-content;
  }
  
}
.panchang-item{
  border-radius: 5px;
}
.payment-options .slick-cloned{
  display: none !important;
}

.table > :not(caption) > * > * {
  background-color: initial !important;
  color:  #fff !important;
}
.game-card{
  cursor: pointer;
}