@media (max-width: 768px) {
  .mobile-filters {
    display: inline-block !important;
    width: 100%;
  }
  .game--name p {
    width: 85%;
  }
  .filters {
    display: none !important;
  }
  .standard-btn.add-new-bank {
    width: 100% !important;
  }
  .parent-winner-slider {
    display: none;
  }
  body {
    background: #111111;
  }
  .if--sc--label {
    margin-top: 1rem;
  }
  .mobile-content {
    display: block;
    background: #111111;
  }
  .hamburger {
    display: none;
  }
  .otp-field {
    border-radius: 5px !important;
  }
  .banner-vdo {
    border-radius: 15px;
    // object-fit: cover;
    // height: 190px;
  }
  .payment-status {
    width: 95%;
  }
  .payment-status-parent {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .video-slide {
    position: relative;
    i {
      right: 0.8rem;
      bottom: 0.5rem;
      font-size: 30px;
    }
  }
  .footer-submenu-container {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 110vh !important;
    background: rgba(1, 1, 1, 0.7);
    .games-menu {
      background: #1b1b1b;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding-top: 1rem;
    }
    z-index: 1;
  }
  .form-mobile.otp-form {
    .border-btn {
      color: #efc04b;
      border: 1px solid #707070;
      width: 25%;
    }
    .login-btn {
      margin-left: 10px;
      width: 70% !important;
    }
  }

  .headline1 {
    width: 100% !important;
  }

  .navbar {
    padding: 0 !important;
    div {
      padding: 0 10px 0 10px !important;
    }
  }
  .bank-text {
    margin-left: 6px !important;
  }

  .menu-items,
  .login-signup-btns {
    display: none !important;
  }

  //   div.slick-slide {
  //     width: 170px !important;
  //   }
  //   .slick-initialized .slick-slide {
  //     display: block;
  //     width: 170px !important;
  //     margin-left: 12px;
  //   }

  .slick-slide > div {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  .games-carousel .slick-slide > div {
    margin-right: 6px;
    margin-left: 6px;
  }
  .slider-custom-style {
    padding: 10px !important;
  }
  .slick-dots li button:before {
    font-size: 10px !important;
  }
  .slick-dots li {
    width: 5px !important;
    height: 5px !important;
  }
  .slick-dots {
    bottom: 0px !important;
  }
  .search-bar-item {
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    position: relative;

    button.standard-btn {
      display: block;
      padding: 0;
      width: 70px;
      margin-left: 8px;
      border-radius: 10px;
      font-size: 12px;
      img {
        margin-right: 3px;
      }
    }
    input {
      border-radius: 10px;
      font-size: 12px;
      padding-left: 10px;
      background: #2d2d2d;
      height: 35px;
      width: 80%;
    }
    img.float-right {
      width: 20px;
      height: 20px;
      margin-top: 7px;
      position: absolute;
      right: 100px;
    }
    .spinner-border {
      position: absolute;
      right: 10px;
      top: -10px;
    }
  }
  .large-screen-footer {
    display: none;
  }
  .small-screen-footer {
    display: block;
    z-index: 9;
    position: relative;
    background: #303030;
    padding-top: 14px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100% !important;
  }
  .footer-submenu-container {
    margin-bottom: -1000px;
    height: auto;
    z-index: -1;
    .row {
      padding-bottom: 100px !important;
      .col-4 {
        padding: 4px !important;
      }
    }
  }
  .show-sub-menu {
    z-index: 1;
    margin-bottom: 0px;
    transition: 0.3s margin-bottom;
  }
  .hide-sub-menu {
    z-index: -1;
    margin-bottom: -1000px;
    transition: 0.3s margin-bottom;
  }
  .game-type {
    background: #303030;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .footer {
    border: none;
    position: fixed;
    left: 0;
    bottom: -5px;
    width: 100%;
  }
  .mobile-footer {
    div {
      text-align: center;
      width: 95px;
      p {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 14px;
        user-select: none;
        margin-top: 4px;
      }
    }
  }
  .slider-custom-style .slide-custom {
    //   width: 70% !important;
    // margin-right: 30px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .main-slider .slider-custom-style .slide-custom {
    //   width: 70% !important;
    // margin-right: 30px;
    img {
      width: 100%;
      object-fit: contain;
      box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3);
      border-radius: 15px;
    }
  }
  .footer-mobile-logo {
    display: table;
    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      margin-top: -52px;
      object-fit: none;
    }
  }
  // .games-section {
  //   padding-bottom: 6rem !important;
  // }
  .headline2 {
    display: none;
  }
  .headline1 {
    padding: 0.3rem !important;
    font-size: 10px !important;
    border: none !important;
    span img {
      width: 30px;
      height: 30px;
    }
  }
  .custom-padding {
    padding: 1rem;
  }
  .games-carousel.custom-padding {
    padding: 0.5rem;
  }
  .games-carousel h4 {
    padding-left: 14px;
    font-size: 16px;
    padding-bottom: 0.5rem !important;
  }
  .games-carousel .slick-arrow {
    display: none !important;
  }
  .games-carousel .slide-custom div.overlay {
    font-size: 16px !important;
    color: #fff !important;
  }
  .logged-in-user-data-in-header {
    .header-username {
      display: grid !important;
      margin-left: 0;
    }
    .deposit {
      display: none;
    }
    .balance-n-deposit {
      min-width: 80px !important;
      padding: 0px !important;
      margin-right: 3px;
      height: 40px;
      max-width: 100px;

      .balance {
        width: 100%;
        padding: 0;
        padding-left: 10px;
        font-size: 12px;
        &:hover {
          .balance-refresh-icon {
            display: none;
          }
        }
      }
    }
  }

  .statement {
    font-size: 10px !important;
  }
  .upi-id-div > div.upi_id {
    padding: 18px !important;
  }
  .upi-id-div .copy-btn {
    padding: 0.87rem 1rem !important;
  }
  .checkbox-upi {
    width: 24px !important;
    height: 20px !important;
  }
  .checkbox-label-upi {
    margin-top: -3px !important;
  }
  .games-carousel .slick-slider .slick-track {
    margin-left: -10px !important;
  }
  .navbar-brand img {
    width: 90px !important;
  }
  .filter-menu {
    border-radius: 0px;
    margin-bottom: 100px;
    z-index: initial !important;
    width: 100% !important;
    overflow-y: initial !important;
    .header {
      border-radius: 0px;
    }
    .spacer {
      display: none;
    }
  }

  .form-mobile {
    display: block;
    b {
      font-size: 14px !important;
      font-weight: 400;
    }
    input#rf_code {
      width: 90%;
    }
    input {
      background: black;
      color: #fff;
      border: none;
      border-radius: 0px 5px 5px 0px;
      padding: 10px;
      &:focus {
        outline: none;
        border: 1px solid #32d172;
      }
    }

    .PhoneInput {
      background: black;
      padding-left: 0px;
      border-radius: 5px;
      width: 100%;
    }
    .form-control.is-valid {
      border-color: transparent;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: 18px !important;
      display: flex;
      padding-top: 0;
      padding-bottom: 0;
    }
    p.remember-me {
      font-size: 10px;
      opacity: 0.5;
      font-weight: 200;
      margin-left: 5px;
    }
    .forgot-password {
      font-size: 14px;
      color: #31b3ff;
      cursor: pointer;
      user-select: none;
    }
    button {
      width: 100%;
      border-radius: 5px;
      color: black;
      font-size: 14px;
      border: none;
    }
    .login-btn {
      background-image: linear-gradient(
        105deg,
        #f1b515 0%,
        #fffb9a 53%,
        #e1900b 100%
      );
    }
    .signup-btn {
      background: none;
      border: 1px solid #f2cb5a;
      color: #fff;
    }
    p.not--account {
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 5px;
    }
  }

  .profile-items {
    overflow-x: scroll !important;
    &::-webkit-scrollbar {
      display: none;
    }
    .profile-item {
      p {
        margin-top: 1px;
      }
    }
  }
  .sub--menu-item {
    padding: 0.7rem;
    margin-bottom: 10px;
    i {
      display: grid;
      place-items: center;
      margin-top: -2px;
    }
  }
  .profile--sm {
    position: relative;
    margin-bottom: 300px;
  }
  .mobile-verification-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    height: 100%;
    background: #111111;
    p.name {
      display: grid;
      place-items: center;
    }
  }
  .standard-btn-div {
    margin-top: 50px;
  }
  .deposit-col1 {
    .px-4 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    padding-top: 0px !important;
  }
  .payment-options {
    // .slick-track {
    //   margin-left: -15px;
    // }
    // .slick-slide > div {
    //   margin-left: 0 !important;
    // }
    // button.slick-arrow {
    //   display: none !important;
    // }
    // .slider-custom-style {
    //   padding-left: 0 !important;
    //   padding-right: 0 !important;
    //   .slide-custom {
    //     padding: 0 !important;
    //   }
    // }
    .slick-active.slick-current img {
      border-radius: 5px !important;
    }
    .selected-slide {
      border: 1px solid #efc04b;
      border-radius: 5px;
    }
  }
  .amount-input > div {
    background: #0b0b0b;
    position: relative;
    input {
      background: none;
      border: none;
      padding: 0.7rem;
      color: #fff;
      width: 100%;
      &:focus {
        outline: none;
        border: none;
      }
      font-size: 18px;
      opacity: 1;
    }
    span {
      position: absolute;
      right: 11px;
      top: 10px;
    }
  }
  .amount-options {
    width: 100% !important;
    overflow-x: scroll !important;
    padding-right: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
    div.d-flex {
      padding-right: 10px;
    }
  }
  .mobile-spacing {
    margin-bottom: 280px;
  }
  .action-btns {
    bottom: 7.5rem !important;
  }
  .available-offers-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .available-offers-container label {
    padding-left: 0;
  }

  .available-offers {
    display: flex;
    padding: 0 !important;
    height: auto !important;
    overflow-x: scroll !important;
    overflow-y: initial !important;
    .offer-card {
      display: block;
      min-width: 80% !important;
      padding: 0.7rem;
      border: 1px solid rgb(72, 72, 72);
      margin-right: 10px;
      border-radius: 5px;
      p.text--normal {
        font-size: 10px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .imps--p2 {
    padding-bottom: 1rem !important;
    p.text-decoration-underline {
      margin-top: 0px !important;
      text-decoration: none !important;
    }
  }
  .imps--p {
    input {
      background: #000;
      border: 1px solid rgb(42, 42, 42);
      border-radius: 5px;
      color: #fff;
    }
  }
  .imps--p1 {
    order: 2;
    padding: 1rem 1.6rem 15rem 1.6rem !important;
  }
  .trn-utr {
    padding-right: 0 !important;
  }
  .upi--p1 {
    div {
      text-align: center;
    }
  }
  .upi--p2 {
    padding: 1rem 1.6rem 12rem 1.6rem !important;
    input {
      background: #000;
      border: 1px solid #707070;
      color: #fff;
      border-radius: 5px;
    }
    p.text-decoration-underline {
      margin-top: 0px !important;
      text-decoration: none !important;
    }
    .upi-id-div {
      background: #2b2020;
      .copy-btn {
        border: none;
        border-radius: 5px;
        margin-top: 1px;
      }
    }
  }
  .request-completed {
    height: 90%;
  }
  .request-completed {
    margin-top: 45%;
    margin-bottom: 50%;
  }
  .added-banks-list .form-check p.text--small {
    padding-left: 22px !important;
  }
  .bank-lists {
    margin-bottom: 220px !important;
    overflow-y: initial !important;
    padding-left: 1.5rem !important;
  }
  button.delete-bank {
    margin-left: 24px !important;
  }
  button.delete-bank.delete-bank-veri {
    margin-left: 22px !important;
  }
  .checkbox-div .form-check-label {
    padding-left: 8px;
  }
  .input.bank-checkbox-input {
    margin-left: 4px !important;
  }
  .payment-error {
    margin: 1rem !important;
  }
  .withdrawl-left {
    .text--small {
      font-size: 8px;
    }
  }
  .mobile--fp {
    b {
      font-size: 14px !important;
      font-weight: 400;
    }
    input {
      background: black;
      color: #fff;
      border: none;
      border-radius: 0px 5px 5px 0px;
      padding: 10px;
      &:focus {
        outline: none;
        border: 1px solid #32d172;
      }
    }

    .PhoneInput {
      background: black;
      border-radius: 5px;
      width: 100%;
    }
    .form-control.is-valid {
      border-color: transparent;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      background-size: 18px !important;
      display: flex;
      padding: 0;
    }
    p.remember-me {
      font-size: 10px;
      opacity: 0.5;
      font-weight: 200;
      margin-left: 5px;
    }
    .forgot-password {
      font-size: 10px;
      color: #31b3ff;
      cursor: pointer;
      user-select: none;
    }
    button {
      width: 100%;
      border-radius: 5px;
      color: black;
      font-size: 14px;
      border: none;
    }
    .login-btn {
      background-image: linear-gradient(
        105deg,
        #f1b515 0%,
        #fffb9a 53%,
        #e1900b 100%
      );
    }
    .signup-btn {
      background: none;
      border: 1px solid #f2cb5a;
      color: #fff;
    }
    p.not--account {
      font-size: 12px;
    }
  }
  .legal-page-back {
    padding: 1.5rem !important;
    background-size: cover;
    background-position: center center;
  }
  .legal-page-back .search-bar-item {
    width: 100% !important;
    padding-right: 10px;
    input {
      width: 100% !important;
      background: none;
    }
  }
  .legal-page-back .search-bar.custom-padding {
    padding: 0 !important;
  }
  .login-history-parent {
    overflow-x: scroll;
    height: 400px;
  }
  .login-history {
    margin-left: -8px;
    width: 600px;
    height: initial !important;
  }
  .password-updated-message {
    margin-top: -45px !important;
    margin-bottom: 16px;
  }
  .pagination {
    margin-bottom: 10px;
  }
  .padding-0 {
    padding: 0;
    .table-container {
      border-right: 1px solid #303030;
      border-top: 1px solid #303030;
      border-left: 1px solid #303030;
    }
  }
  .pagination-table .td-item {
    min-width: 80px !important;
    padding: 0px !important;
    font-weight: 300;
    font-size: 12px;
  }
  .pagination-table th {
    font-size: 12px;
  }
  .custom-date-picker input {
    padding: 0.5rem 0.6rem !important;
  }
  .finance {
    .filter-payment-method {
      height: 42px !important;
      margin-top: -2px;
      padding-top: 7px !important;
    }
  }
  .game-transactions {
    .filter-payment-method {
      height: 42px !important;
      margin-top: -2px;
      padding-top: 7px !important;
    }
  }
  .profile--sm {
    form {
      margin-top: -20px !important;
    }
  }
  .copy-btn span {
    position: absolute;
    width: 100px;
    background: #444444;
    padding: 5px;
    border-radius: 3px;
    top: 0px !important;
    right: 100% !important;
    left: -110px !important;
    text-align: center !important;
    font-size: 10px !important;
    div {
      position: absolute;
      top: 40%;
      left: 97%;
      transform: rotate(45deg);
      height: 6px !important;
      width: 6px !important;
      background: #444444;
    }
  }
  .top-cards{
    height: auto;
    flex-direction: column;
    .astro-user-detail-card{
      width: 100%;
      .content1{
        transform: translate(-20%, -50%);
      }
      .content2{
        transform: translate(-25%, -50%);
      }
    }
  }
  .horo-section{
    padding: 2rem 1.5rem !important;
  }
  .horo-section .timeframe-dropdown{
    width: 120px;
  }
  .horo-section .timeframe-dropdown .selected-timeframe{
    padding: 10px 20px;
    width: 100px;
  }
  .text--2xl{
    font-size: 25px !important;
  }
  .numerology-num-p{
    display: none;
  }
  .numerology-scroll-section{
    height: 450px;
    overflow-y: scroll;
  }
  .numerology-scroll-section{
    padding: 0;
    .w-75{
      width: 100% !important;
    }
  }
  .hero-section-3{
    padding: 0 !important;
  }
  .panchang-overlay{
    padding: 2rem 1.5rem !important;
    .standard-btn{
      display: none;
    }
    .top-content{
      flex-direction: column !important;
      img{
        padding: 0 !important;
        width: 150px !important;
        margin-bottom: 2rem !important;
      }
    }
  }
  .panchang-items{
    margin-bottom: 2rem;
  }
  .panchang-item{
    padding: 0.5rem !important;
    p{
      font-size: 14px;
    }
  }
  .balance-refresh-icon{
    display: none !important;
    .show{
      display: none !important;
    }
  }
  .bi-arrow-clockwise{
    display: none !important;
  }
}

//end of mobile css

@media (max-width: 991px) {
  .gift-icon,
  .bell-icon,
  .header-username {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .navbar {
    height: 70px;
    .container-fluid {
      padding-left: 10px !important;
      padding-right: 10px !important;
      .navbar-brand img {
        width: 95px;
        object-fit: contain;
      }
      .menu-items {
        height: 70px;
        .menu-item {
          width: 115px;
        }
      }
    }
  }
  .logged-in-user-data-in-header {
    .header-username {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
  .footer-logo p {
    padding-right: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1500px) {
  .custom-padding {
    padding: 2rem 4rem 2rem 4rem;
  }
  .main-slider .slick-track {
    margin-left: -150px;
  }
}
@media (min-width: 1500px) {
  .main-slider .slick-track {
    margin-left: -300px;
  }
}
@media (max-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1060px;
  }
  .user-profile {
    .col-3,
    .col-4,
    .col-6,
    .col-9 {
      padding: 5px !important;
    }
  }
  .payment-status {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .margin--bottom {
    margin-bottom: 0rem;
  }
  .payment-status {
    width: 95%;
  }
  .veification--sm,
  .balance-sm,
  .security-sm {
    margin-bottom: 250px !important;
  }
  .ledger--sm {
    margin-bottom: 450px !important;
  }
  button.standard-btn.deposit-now {
    padding: 1rem !important;
    border-radius: 10px;
  }
  .finance-table {
    .table td:last-child {
      background: #111111;
      div {
        background-color: #111111;
      }
    }
    .table th:last-child {
      background: black;
      div {
        background-color: #111111;
      }
    }
  }
  .signup-form-inputs input {
    &:not([type="checkbox"]) {
      background: #000 !important;
      color: #fff;
    }
    &:focus {
      box-shadow: none !important;
      border: none !important;
      outline: none !important;
    }
  }
  .amount:hover {
    background: #353535 !important;
    color: #fff !important;
    opacity: 0.75;
  }
  .amount:active {
    background: linear-gradient(
      180deg,
      rgb(233, 184, 49) 0%,
      rgba(255, 251, 154, 1) 50%,
      rgb(233, 184, 49) 100%
    ) !important;
    color: #000 !important;
    opacity: 1 !important;
    font-weight: 400 !important;
    transform: scale(0.95) !important;
  }
  .trn-utr input:focus {
    background: #000 !important;
    outline: none !important;
    box-shadow: none !important;
    color: #acacac;
  }
  .chat--box {
    position: relative;
    border: none !important;
    width: 100% !important;
    right: 0;
    bottom: 0;
    border-radius: 0px !important;
    height: 85vh;
    z-index: initial !important;
  }
  .chatbox-header {
    border-top: 1px solid #efc04b;
  }
  .total-bal p.text--normal,
  .withdrawl-bal p.text--normal,
  .bonus-bal p.text--normal {
    font-size: 15px;
  }
  .total-bal p.mb-0 b,
  .withdrawl-bal p.mb-0 b,
  .bonus-bal p.mb-0 b {
    font-size: 18px !important;
  }
  .alert-popup {
    width: 95% !important;
  }
  .balance-refresh-icon {
    i {
      font-size: 20px !important;
    }
  }
  .phone-amount-font {
    font-size: 14px !important;
  }
  .mobile-footer {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1300px;
  }
  .white-stp {
    width: 70% !important;
    margin: auto;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1475px !important;
  }
}
@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 1000px;
  }
}
